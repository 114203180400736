import React from "react";
import { Typography } from "@mui/material";
import Header from "../components/Header";
import { Container } from "@mui/system";
import Footer from "../components/Footer/Footer";
const PrivacyPolicy = () => {
  return (
    <>
      <div id='google_translate_element'></div>
      <Container>
        <Header isTransparent />
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          Privacy Policy
        </Typography>
        <p>M/S YELLA INSURANCE BROKING PVT. LTD. RESPECTS YOUR PRIVACY!</p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          1. Introduction
        </Typography>
        <p>
          This Privacy Policy sets out how the information provided by you is
          collected, used, stored, processed, transferred and protected. Please
          read the terms carefully. By accessing the Website or using any of our
          services, You agree to be bound by all the terms of this Privacy
          Policy.
        </p>
        <p>
          For the purposes of this Privacy Policy, ‘we’, ‘us’, ‘our’, ‘Yella’
          and “Company” refers to M/S Yella Insurance Broking Pvt Ltd (including
          its representatives, POSP Partners and Affiliates) and ‘you’ refers to
          the employees both permanent and contractual in nature, probationers,
          contractors, vendors, agents, consultants, advisors, interns,
          trainees, customers and users of the services provided at all levels
          by the Company.
        </p>
        <p>
          "Affiliate" means any Person directly, or indirectly through one or
          more intermediaries, that Controls, is Controlled by or is under
          common Control with Yella.
        </p>
        <p>
          "Control," as used in the immediately preceding sentence, shall mean
          with respect to any person, the possession, directly or indirectly, of
          the power, through the exercise of voting rights, contractual rights
          or otherwise, to direct or cause the direction of the management or
          policies of the controlled person.
        </p>
        <p>
          "Person" includes any natural person, corporation, partnership,
          Limited Liability Company, trust, unincorporated association, or any
          other entity.
        </p>
        <p>
          By using our website-vkover.com (“Website”)/mobile
          application-vkover.com (“App”) and/or registering yourself at the
          Website/App or any other web domain owned by the Company, you
          authorize us to contact you via email or phone call or SMS or WhatsApp
          or any other electronic medium and offer you the services for the
          product(s) you have opted for based on your needs, impart product
          knowledge, offer insurance solutions, offer other promotional offers
          running on Website/App. Irrespective of the fact that you have
          registered yourself under DND or DNC or NCPR service, by registering
          on the Website/App, you still authorize us to give you a call from
          vkover, M/S Yella Insurance Broking Pvt Ltd, its affiliates, holding,
          subsidiaries and group companies, POSP partners, insurers associated
          with us or otherwise. By registration, you also authorize us to send
          Messages/SMS/Email alerts to you for your login details and any other
          service requirements or some advertising messages/emails from us. "If
          you are no longer interested in receiving e-mail announcements and
          other marketing information from us, or you want us to remove any
          information that we have collected about you, please e-mail your
          request to the Data Grievance Officer."
        </p>
        <p>
          This policy does not apply to the practices of companies that we do
          not own or control or to people that we do not employ or manage.
        </p>
        <p>
          We understand and believe that the information provided by you is
          correct. However, please understand that the provisions of the
          services provided by us will be discontinued to you, at our sole
          discretion, if any information provided by you is untrue, inaccurate,
          out of date or incomplete or subsequently becomes untrue, inaccurate,
          out of date or incomplete, or there are reasonable grounds to suspect
          that the information provided by you is untrue, inaccurate, out of
          date or incomplete.
        </p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          2. INFORMATION WE COLLECT
        </Typography>
        <p>We will collect the following information from you:</p>
        <p>
          a) Personal Information that will mean and include all kind of
          information which can be linked to a specific individual or to
          identify any individual, directly or indirectly, such as name, age,
          address, contact details (phone numbers/ email address), date of
          birth.
        </p>
        <p>
          b) Clickstream data that includes information about your interaction
          with various elements of our Website and App.
        </p>
        <p>
          c) If appropriate permissions are provided by you, we collect the GPS
          location on various platforms that you use the service on App/Website.
          Location might be collected at certain times that might depend on your
          App/Website usage or the time of the day.
        </p>
        <p>d) Vehicle information whenever opting for motor insurance.</p>
        <p>
          e) Health and lifestyle information which is collected only as per the
          requirements of the insurer to underwrite the proposal as per the
          guidelines of Insurance Regulatory and Development Authority of India
          (“IRDAI”) for health &amp; life insurance.
        </p>
        <p>
          f) Health and lifestyle related questions/information, which are
          mandated by the insurance company for the purpose of issuing insurance
          policy.
        </p>
        <p>
          g) Details required in a KYC document which is the mandatory document
          as per the guidelines of IRDAI.
        </p>
        <p>
          h) Documentary proof/information of educational qualifications,
          wherever applicable
        </p>
        <p>i) Financial data including bank account number, IFSC Code.</p>
        <p>
          j) Constitution details of a vendor including without limitation,
          name, office address, Articles of Assocation.
        </p>
        <p>k) Biometric Information, wherever applicable.</p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          3. PURPOSE, USE AND STORAGE OF COLLECTED INFORMATION
        </Typography>
        <p>
          The information/data collected (as mentioned above) may be used for
          analytics to ultimately improve our service offerings to you. It helps
          us to find geographical areas that might require a higher degree of
          support, provide appropriate leads or personalize the App/Website
          experience. The information/data is also collected for the purposes of
          providing proper services, verification of the identity and usage
          information.
        </p>
        <p>Information collected will be used for the following purposes:</p>
        <p>a) For issuance of the insurance policy that you have opted for.</p>
        <p>b) For providing information about various products and services.</p>
        <p>
          c) For addressing queries put forth by you and for resolving the
          concerns pertaining to any service or product.
        </p>
        <p>
          d) For processing your transactions and also to provide you
          transaction and post transaction-related services.
        </p>
        <p>
          e) For providing, improving, and marketing our products and services,
          including site content and performance.
        </p>
        <p>f) For sending you survey and marketing communications.</p>
        <p>
          g) For facilitating various programmes and initiatives launched either
          by us or third party which we believe may be of an interest to you.
        </p>
        <p>h) For facilitating usage of our Website/App.</p>
        <p>
          i) For improving our services, product or content on our Website/App.
        </p>
        <p>
          j) For providing group insurance cover and wellness corner offerings.
        </p>
        <p>
          k) For providing health and wellness related information to you that
          are offered by insurer.
        </p>
        <p>
          l) For sending notices, communications, offer alerts and to contact
          you over the telephone/
        </p>
        <p>
          mobile number and/or e-mail address provided by you for sending
          information of our
        </p>
        <p>
          products in which you have shown your interest, policy renewal
          reminders, other service
        </p>
        <p>details and other general information about insurance</p>
        <p>m) For appointment as Point of Salesperson /employee.</p>
        <p>
          n) For payment of salary/commission earned and related circumstances
          therein.
        </p>
        <p>o) For verification purposes.</p>
        <p>
          p) For the purpose of joining of the employee and for validation of
          the details of the curriculum vitiate.
        </p>
        <p>
          q) For bank account details will be used for the purpose of
          salary/commission credit to employees/POSP and for payment to
          vendors/suppliers.
        </p>
        <p>r) For biometric information for attendance purposes.</p>
        <p>s) To improve our products and enhance customer service.</p>
        <p>
          t) To extend services or administer a contest, promotion, survey or
          other site or business feature.
        </p>
        <p>
          u) For Aadhaar authentication and sharing, storing, using Aadhaar
          data.
        </p>
        <p>v) For sales and marketing Activities</p>
        <p>w) Allow you to access specific account information.</p>
        <p>
          x) To process transactions, where requested, under your User ID and
          Password. All the data/information collected may be stored on the
          infrastructure provided by third party cloud service in India which is
          fully compliant with regulatory requirements within. No
          information/data that is collected ever goes out of India.
        </p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          4. INFORMATION SHARING AND DISCLOSURE
        </Typography>
        <p>
          We will not share the information that we have collected from you to
          anyone other than as specifically noted herein below:
        </p>
        <p>
          a) For the purpose of facilitating sale and servicing of insurance
          products to you, as an insurance broker, we provide your personal
          information to the insurers that are chosen by you on the App or
          Website.
        </p>
        <p>
          b) Otherwise than stated in clause 4f herein below, we do not share or
          disclose the information to any other individual/institutions and
          their subsidiaries/associates/holding company/ subsidiaries companies/
          group companies/ affiliates, tie-up institutions etc. for any purpose
          unless such a disclosure is explicitly authorised by you. Yella
          Response- We have suitable changes in wordings.
        </p>
        <p>
          c) Where information collected by us is required to be disclosed to
          comply with any applicable law, regulation and legal process; in
          response to law enforcement authority or other governmental official
          request; to detect, prevent, or otherwise address fraud, cyber
          incidents, prosecution, and punishment of offences; for security or
          technical issues; to investigate a complaint or security threat and
          for any public interests.
        </p>
        <p>
          d) For marketing purpose but only under circumstances where you have
          specifically authorized or consented for the same.
        </p>
        <p>
          e) Where we are required to disclose the information as a part of any
          statutory obligation to IRDAI or any other statutory authority.
        </p>
        <p>
          f) We may share and/or transfer your information to any of our
          Affiliate, holding/ subsidiaries/ group companies and/or insurers. Our
          affiliates, holding/ subsidiaries/ group companies may also from time
          to time send by e-mail or otherwise, information relating to their
          products or services.
        </p>
        <p>
          g) We may also share and/or transfer your information to any
          successor-in-interest as a result of a sale of any part of our
          business or upon the merger, reorganization or consolidation of YIBPL
          with another entity on the basis that Yella is not the surviving
          entity.
        </p>
        <p>
          h) We may share personal information with our other corporate entities
          and affiliates to help us operate our business and the Site or
          administer activities such as detection of identity theft, fraud and
          other potentially illegal acts, to facilitate joint or co-branded
          services that you request where such services are provided by more
          than one corporate entity. We may share your information with these
          third parties for those limited purposes provided that you have given
          us your permission.
        </p>
        <p>
          Where you provide Personal Information of
          beneficiaries/dependents/other individuals to the Company, you agree
          (i) To inform the individual about the content of this Privacy Policy
          and (ii) to obtain any legally-required consent of Personal
          Information about the individual in accordance with this Privacy
          Policy
        </p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          5. RIGHTS UNDER THE POLICY
        </Typography>
        <p>
          The below mentioned rights can be exercised by you as and when we
          obtain or hold personal information about you, or access personal
          information we hold about you, and for obtaining its correction,
          update, amendment, or deletion in appropriate circumstances where
          permitted by applicable law or regulation. Some of these rights may be
          subject to some exceptions or limitations.
        </p>
        <p>
          You have the right to Access your personal data held about you and to
          learn the origin of the data, the purposes and means of the
          processing, the details of the data controller(s), the data
          processor(s) and the parties to whom the data may be disclosed.
        </p>
        <p>
          Withdraw your consent at any time where your personal data is
          processed with your consent.
        </p>
        <p>Update and correct your personal data so that it is accurate;</p>
        <p>
          Delete your personal data from our records if it is no longer needed;
        </p>
        <p>
          Restrict the processing of your personal data in certain
          circumstances, e.g.&nbsp;where you have contested the accuracy of your
          personal data, for the period enabling us to verify its accuracy;
        </p>
        <p>Obtain your personal data in an electronic format;</p>
        <p>
          File a complaint with us and/or the relevant data grievance officer;
          and
        </p>
        <p>
          Object to us processing your personal data, or tell us to stop
          processing it (including for purposes of direct marketing, survey etc)
        </p>
        <p>
          The above rights can be exercised by you by approaching to the Data
          Grievance Officer.
        </p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          6. SECURITY MEASURES
        </Typography>
        <p>
          Protection of your information is of paramount importance to us.We
          adopt appropriate data collection, storage and processing practices
          and security measures to protect against any unauthorized access,
          alteration, disclosure or destruction of your information, username,
          password, transaction information and any other information/data
          stored with us. Some of the security measures taken by us are as
          following:
        </p>
        <p>
          a) The information collected by us is stored on servers that are
          secured behind a firewall. Further, the access to the servers is
          password-protected and is strictly limited.
        </p>
        <p>
          b) All information transmitted to and from our server is fully
          encrypted.
        </p>
        <p>
          c) The data that is passed in APIs is passed over secure TLS based
          http connection.
        </p>
        <p>
          d) We do not store any payment or cards related information on our
          servers. Your data stored in our systems is not visible to anyone.
        </p>
        <p>
          e) The system architecture isolates insurer code to ensure that there
          is no possibility of cross-talk between insurer systems.
        </p>
        <p>
          f) All the communication with insurer is push-based, i.e., only
          required information is pushed to the insurer system. Insurer cannot
          pull out data from the system of vkover
        </p>
        <p>
          g) Each proposal and the transaction that is done is tagged to a
          customer and agent through an email id which is then protected by a
          password. A policy will be accessible only to an agent or the
          customer.
        </p>
        <p>
          h) Your personal information in vkover account is not visible to
          anyone. End-to-End encryption is used to transmit data between all
          entities, to protect against network vulnerabilities.
        </p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          7. UPDATE/CORRECTION/DELETION OF INFORMATION
        </Typography>
        <p>
          You have the right to access your personal information. You also have
          the right to request correction and deletion of the information shared
          by you. Changes can be made in system as and when there is any
          requirement for a change in the information. Personal information can
          be corrected, deleted or amended by contacting us. We will make
          efforts in good faith to make requested changes in our then-active
          databases as soon as reasonably possible. Thus, you may contact us
          through the Grievance Officer to have your information
          updated/corrected/deleted with us.
        </p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          8. RETENTION PERIOD
        </Typography>
        <p>
          All information collected by us will be stored with us as long as it
          is necessary for us to fulfill our obligations in respect of the
          provision of the services and in accordance with the applicable laws.
          Information collected by us can be retained by us if we believe it to
          be necessary to prevent fraud or future abuse; if required by law, for
          other legitimate purposes.
        </p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          9. CONSENT
        </Typography>
        <p>
          By using our Website/App and by providing your information, it is
          assumed that you have provided your express consent to the collection
          and use of the information which is disclosed in accordance with this
          Privacy Policy, including but not limited to, your express consent for
          sharing this information as per this Privacy Policy. We recommend that
          you do not use/access and/or continue to use/access the Website/App if
          you do not agree to the terms and conditions of this Privacy Policy.
          We obtain your consent depending on our relationship with you. Thus,
          the consent is obtained in the following manner:
        </p>
        <p>
          a) Consent of prospects/customers: Consent of the customer is taken on
          the proposal form/ through acceptance of the terms and conditions of
          our App or website.
        </p>
        <p>
          b) Consent of Partners/POSP: Consent of the Partner/POSP is obtained
          through execution of the agreement with the Company/acceptance of the
          terms and conditions of our App or website during enrolling stage.
        </p>
        <p>
          c) Consent of Vendors/Suppliers: Consent of the vendor/supplier is
          obtained by execution of the agreement with the Company and through
          various others self-attested documents/ questionnaire.
        </p>
        <p>
          d) Consent of the Employees: Consent of an employee is obtained
          through the forms filled up by an employee at the time of joining
          through the joining documents and/ or through self-attested
          testimonials and other KYC documentation.
        </p>
        <p>
          By using our website or by providing your information as mentioned
          above, it is assumed that you have provided your express consent to
          the collection and use of the information, which is disclosed in
          accordance with this Privacy Policy, including but not limited to,
          your express consent for sharing this information as per this Privacy
          Policy. We recommend that you do not use/access and/or continue to
          use/access the website, WAP service or app if you do not agree to the
          terms and conditions of this Privacy Policy You have an option to
          refuse giving your consent or take back your consent in a way as
          specified below:
        </p>
        <p>
          a) Customers can choose not to continue buying our product/services
          and drop the transaction by not making payment.
        </p>
        <p>
          b) Vendors have the right to terminate the contract or modify the
          terms of the contract while the same is in force.
        </p>
        <p>
          c) Partners/POSP can drop the decision to become a Partner or after
          becoming a Partner, request for termination can be made and NOC can be
          taken from us with respect to the same.
        </p>
        <p>
          d) Employee has the option to not join the Company or if the employee
          has joined the Company, he/she can modify the information submitted to
          us."
        </p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          10. COOKIES
        </Typography>
        <p>
          A cookie is a small text file that is stored on a computer for
          record-keeping purposes. We use cookies and other technologies such as
          pixel tags and clear gifs to store certain types of information each
          time you visit any page on our Website/App. Cookies enable to
          recognize the information you have consented to give to Website/App
          and help us determine what portions of our Website/App are most
          appropriate for your professional needs. We may also use cookies to
          serve advertising banners to you. These banners may be served by us or
          by a third party on our behalf. These cookies will not contain any
          personal information.
        </p>
        <p>
          Whether you want your web browser to accept cookies or not is up to
          you. If you haven't changed your computer's settings, most likely your
          browser already accepts cookies. If you choose to decline cookies, you
          may not be able to fully experience all features of the Website/App.
          You can also delete your browser cookies or disable them entirely. But
          this may significantly impact your experience with our Website/App and
          may make parts of the Website/App non-functional or inaccessible. We
          recommend that you leave them turned on.
        </p>
        <p>
          Third Party Cookies Third parties may place cookies on this vkover
          site. The information practices of these third parties are not covered
          by our Privacy Policy. Please contact them directly for more
          information about their privacy practices. Also, as described above,
          you may modify the settings in your browser to notify you when you
          receive a new cookie and disable cookies altogether.
        </p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          11. OTHER SITES
        </Typography>
        <p>
          There might be affiliates or other sites linked to our Website/App.
          Thus, when you visit those sites, the affiliated sites may have
          different privacy practices and we encourage you to read their privacy
          policies. These sites may place their cookies or other files on your
          computer, collect data or solicit personal information.
        </p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          12. ACCEPTANCE OF THESE TERMS
        </Typography>
        <p>
          By using our Website/App, you signify your acceptance of this Privacy
          Policy. If you do not agree to this Privacy policy, please do not use
          our Website/App. Your continued use of our Website/App following the
          posting of changes to this Privacy Policy will be deemed as your
          acceptance of those changes.
        </p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          13. DATA GRIEVANCE OFFICER
        </Typography>
        <p>
          In case you have any grievances with respect to in accordance with
          applicable law on Information Technology and rules made there under,
          the name and contact details of the Grievance Officer are provided
          below:
        </p>
        <p>Name: - Mr.&nbsp;Jithin S K</p>
        <p>Designation: - Manager - IT</p>
        <p>
          Address: - 36/3120,2<sup>nd</sup> Floor,Mariam Tower,K K
          Road,Kathrikadavu,Cochin-Kerala-682 017
        </p>
        <p>Email: - info@vkover.com</p>
        <p>
          For any queries or grievances pertaining to this privacy policy or
          collection, storage, retention or disclosure of your information under
          the terms of this Privacy Policy, you can contact to the Grievance
          Redressal Officer at info@vkover.com.
        </p>
        <p>
          Not-withstanding anything mentioned before, we reserve the right to
          contact you via any mechanisms, not limited to email, sms, or phone
          calls, to solicit feedback of your experience and to provide any
          additional services that you would be eligible for, either as a
          customer or as a visitor. We should not be held liable for any damages
          occurring to you as part of feedback solicitation and additional
          service provision.
        </p>
        <Typography
          variant='h1'
          fontSize={"28px"}
          mt={2}
          fontWeight='400'
          mb={2}
        >
          14. CHANGES TO THIS PRIVACY POLICY
        </Typography>
        <p>
          M/S Yella Insurance Broking Pvt Ltd reserves the right to amend or
          modify this Privacy Policy at any time, as and when the need arises.
          We request you to regularly check this Privacy Policy from time to
          time to keep you apprise of changes made. Your continued use of the
          Website gives your unconditional acceptance to such change in terms of
          Privacy Policy
        </p>
        <p>Grievance Redressal</p>
        <p>
          We would like to hear from you in the case of any complaints or
          grievances. We will always attend to your case and give a solution
          post our investigation
        </p>
        <p>
          <strong>Escalation Matrix for Grievance Redressal</strong>
        </p>
        <p>
          <strong>Level 1:</strong>&nbsp;Please email us at
          contact@vkover.com&nbsp; Or call us at&nbsp;9526189000
        </p>
        <p>
          <strong>Level 2:</strong>&nbsp;If you are not satisfied with the
          resolution of your grievance, mail at&nbsp;
          <a href='mailto:vijay@vkover.com' class='email'>
            vijay@vkover.com
          </a>
        </p>
        <p>
          <strong>Level 3:&nbsp;</strong>If you are not satisfied with the
          resolution of your grievance or your grievance is still unresolved,
          write a mail to our Grievance Officer at{" "}
          <a href='mailto:grievance.officer@vkover.com' class='email'>
            grievance.officer@vkover.com
          </a>
          &nbsp;or call at 9526189000.
        </p>
        <p>
          <strong>Level 4:</strong>&nbsp;If the solution provided still does not
          meet your expectations, register with IRDA Integrated Grievance
          Management System (IGMS)
        </p>
        <p>
          <strong>
            For more information on how IGMS works, visit the link
          </strong>
        </p>
        <p>
          <a href='https://www.irdai.gov.in/ADMINCMS/cms/NormalData_Layout.aspx?page=PageNo226&amp;mid=14.3'>
            <span class='ul'>Click Here</span>
          </a>
        </p>
        <p>
          IRDA Grievance Call Center can be accessed through &nbsp; &nbsp;
          &nbsp; &nbsp;&nbsp;<strong>&nbsp;</strong>
        </p>
        <ul>
          <li>
            <p>Toll-free number 1800 4254 732</p>
          </li>
          <li>
            <p>
              <a href='mailto:complaints@irda.gov.in'>
                <span class='ul'>complaints@irda.gov.in</span>
              </a>
            </p>
          </li>
        </ul>
        <p>Policy Cancellation &amp; Refund</p>
        <p>
          Policy cancellation and refund of the premium shall be as per the
          terms and conditions of the policy. The refunds are processed by the
          Insurance Company directly. You are requested to contact the toll free
          number of your Insurance Company or refer the respective section of
          your Policy terms and conditions. You can also call us at 9526189000
          or mail to us at&nbsp;contact@vkover.com. We shall be available to
          guide/assist you.
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
